<template>
  <div>
    <Dialog
      :header="userId == null ? $t('users.addNewUser') : $t('users.updateUser')"
      v-model:visible="viewDialog"
      :draggable="false"
      :modal="true"
      :style="{ width: '50vw' }"
    >
      <div class="grid flex-column">
        <div class="field p-fluid col-12 md:col-7 lg:col-9 m-0">
          <InputText
            id="username"
            type="text"
            :class="{ 'p-invalid': v$.user.username.$invalid && submitted }"
            v-model="v$.user.username.$model"
            aria-describedby="username-error"
            :placeholder="$t('users.username') + ' *'"
          />
          <!-- <small
            v-if="
              (v$.user.username.$invalid && submitted) ||
              v$.user.username.$pending.$response
            "
            class="p-error"
            >{{
              v$.user.username.required.$message.replace(
                "[value]",
                $t("users.username")
              )
            }}</small
          > -->
        </div>
        <ul
          v-if="v$.user.username.$error && submitted"
          class="p-error text-xs col-12 pt-0 mt-0 pl-5"
        >
          <li v-for="(error, index) of v$.user.username.$errors" :key="index">
            {{ error.$message.replace("[value]", $t("users.username")) }}
          </li>
        </ul>
      </div>
      <div class="grid grid-nogutter align-items-baseline">
        <div class="col-12 md:col-2 lg:col-2">
          <p>{{ $t("users.userRole") }} :</p>
        </div>
        <div class="col-12 md:col-7 lg:col-6">
          <div class="p-field p-fluid">
            <span class="p-float-label">
              <MultiSelect
                v-model="user.roles"
                :options="roles"
                optionLabel="name"
                :filter="true"
                display="chip"
                :showToggleAll="false"
                :filterFields="['translatedName']"
              >
                <template #chip="slotProps">
                  {{ slotProps.value.translatedName }}
                </template>
                <template #option="slotProps">
                  {{ slotProps.option.translatedName }}
                </template>
              </MultiSelect>
            </span>
          </div>
        </div>
      </div>
      <Divider />
      <div class="grid align-items-baseline">
        <div class="col-12 md:col-2 lg:col-2 pb-0 md:pb-3">
          <label for="email">{{ $t("users.email") }} *:</label>
        </div>
        <div class="col-12 md:col-7 lg:col-6 pt-0 md:pt-3">
          <div class="field p-fluid m-0">
            <InputText
              id="email"
              type="email"
              :class="{ 'p-invalid': v$.user.email.$invalid && submitted }"
              v-model="v$.user.email.$model"
              aria-describedby="email-error"
            />
            <!-- <span v-if="v$.user.email.$error && submitted">
              <span
                id="email-error"
                v-for="(error, index) of v$.user.email.$errors"
                :key="index"
              >
                <small class="p-error">{{
                  error.$message.replace("[value]", $t("global.email"))
                }}</small>
              </span>
            </span>
            <small
              v-else-if="
                (v$.user.email.$invalid && submitted) ||
                v$.user.email.$pending.$response
              "
              class="p-error"
              >{{
                v$.user.email.required.$message.replace("[value]", "Email")
              }}</small
            > -->
          </div>
        </div>
        <ul
          v-if="v$.user.email.$error && submitted"
          class="p-error text-xs col-10 col-offset-0 md:col-offset-2 pt-0 mt-0 pl-5"
        >
          <li v-for="(error, index) of v$.user.email.$errors" :key="index">
            {{ error.$message.replace("[value]", $t("global.email")) }}
          </li>
        </ul>
      </div>

      <div class="grid align-items-baseline">
        <div class="col-12 md:col-2 lg:col-2 pb-0 md:pb-3">
          <label for="firstName">{{ $t("users.firstName") }} *:</label>
        </div>
        <div class="col-12 md:col-7 lg:col-6 pt-0 md:pt-3">
          <div class="field p-fluid m-0">
            <InputText
              id="firstName"
              type="text"
              :class="{ 'p-invalid': v$.user.firstName.$invalid && submitted }"
              v-model="v$.user.firstName.$model"
              aria-describedby="firstName-error"
            />
            <!-- <small
              v-if="
                (v$.user.firstName.$invalid && submitted) ||
                v$.user.firstName.$pending.$response
              "
              class="p-error"
              >{{
                v$.user.firstName.required.$message.replace(
                  "[value]",
                  $t("users.firstName")
                )
              }}</small
            > -->
          </div>
        </div>
        <ul
          v-if="v$.user.firstName.$error && submitted"
          class="p-error text-xs col-10 col-offset-0 md:col-offset-2 pt-0 mt-0 pl-5"
        >
          <li v-for="(error, index) of v$.user.firstName.$errors" :key="index">
            {{ error.$message.replace("[value]", $t("users.firstName")) }}
          </li>
        </ul>
      </div>

      <div class="grid align-items-baseline">
        <div class="col-12 md:col-2 lg:col-2 pb-0 md:pb-3">
          <label for="lastName">{{ $t("users.lastName") }} *:</label>
        </div>
        <div class="col-12 md:col-7 lg:col-6 pt-0 md:pt-3">
          <div class="field p-fluid m-0">
            <InputText
              id="lastName"
              type="text"
              :class="{ 'p-invalid': v$.user.lastName.$invalid && submitted }"
              v-model="v$.user.lastName.$model"
              aria-describedby="lastName-error"
            />
            <!-- <small
              v-if="
                (v$.user.lastName.$invalid && submitted) ||
                v$.user.lastName.$pending.$response
              "
              class="p-error"
              >{{
                v$.user.lastName.required.$message.replace(
                  "[value]",
                  $t("users.lastName")
                )
              }}</small
            > -->
          </div>
        </div>
        <ul
          v-if="v$.user.lastName.$error && submitted"
          class="p-error text-xs col-10 col-offset-0 md:col-offset-2 pt-0 mt-0 pl-5"
        >
          <li v-for="(error, index) of v$.user.lastName.$errors" :key="index">
            {{ error.$message.replace("[value]", $t("users.lastName")) }}
          </li>
        </ul>
      </div>
      <div class="grid vertical-container align-items-center">
        <div class="col-12 md:col-2 lg:col-2 pb-0 md:pb-3">
          <label for="phone">{{ $t("users.phone") }} :</label>
        </div>
        <div class="col-12 md:col-7 lg:col-6 pt-0 md:pt-3">
          <div class="field p-fluid m-0">
            <InputText
              id="phone"
              type="phone"
              v-model="user.telephone"
              aria-describedby="phone-error"
            />
          </div>
        </div>
      </div>
      <div class="grid vertical-container align-items-center">
        <div class="col-12 md:col-2 lg:col-2 pb-0 md:pb-3">
          <label for="email">{{ $t("users.company") }} :</label>
        </div>
        <div class="col-12 md:col-7 lg:col-6 pt-0 md:pt-3">
          <div class="field p-fluid m-0">
            <InputText
              id="company"
              type="company"
              v-model="user.companyName"
              aria-describedby="company-error"
            />
          </div>
        </div>
      </div>

      <div class="grid vertical-container align-items-center">
        <div class="col-12 md:col-2 lg:col-2 pb-0 md:pb-3">
          <label for="language">{{ $t("users.language") }} :</label>
        </div>
        <div class="col-12 md:col-7 lg:col-6 pt-0 md:pt-3">
          <div class="field p-fluid m-0">
            <Dropdown
              id="language"
              v-model="user.language"
              :options="languages"
              optionLabel="name"
              optionValue="code"
              aria-describedby="language-error"
            />
          </div>
        </div>
      </div>

      <div class="grid vertical-container">
        <div class="col-12 md:col-1 lg:col-1 align-self-center">
          <label for="enabled">{{ $t("global.status") }}:</label>
        </div>
        <div
          class="col-3 md:col-2 lg:col-2 xl:col-1 col-offset-0 md:col-offset-2 lg:col-offset-1 align-self-center"
        >
          <p>{{ $t("global.inactive") }}</p>
        </div>
        <div class="col-3 md:col-2 lg:col-2 xl:col-1 pl-4">
          <InputSwitch v-model="user.enabled" />
        </div>
        <div class="col-3 md:col-2 lg:col-2 xl:col-1 align-self-center">
          <p>{{ $t("global.active") }}</p>
        </div>
      </div>

      <div
        class="grid vertical-container align-items-center"
        v-if="userId != null"
      >
        <div class="col-1">
          <label for="history">{{ $t("users.history") }}:</label>
        </div>
        <div class="col-0 col-offset-1">
          <!-- <p>{{ $t("users.loggedIn") }} 2 min {{ $t("users.ago") }}</p> -->
        </div>
        <div class="col">
          <router-link :to="{ path: '/profile', query: { userId: user.id } }">
            {{ $t("users.viewUserActions") }}
          </router-link>
        </div>
      </div>
      <div class="col px-0">
        <span>* {{ $t("global.mandatoryFields") }}</span>
      </div>
      <template #footer>
        <Button
          :label="userId == null ? $t('global.create') : $t('global.update')"
          class="p-button-success"
          @click="create"
          autofocus
          :loading="loadingButton"
          iconPos="right"
        />
        <Button
          :label="$t('global.cancel')"
          @click="close"
          class="p-button-danger"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import {
  required,
  email
} from "@/utilities/i18n-validators";
import { useVuelidate } from "@vuelidate/core";
import { mapGetters } from "vuex";

export default {
  name: "Add user",
  props: ["display", "userId"],
  emits: ["closeAddUserDialog"],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      viewDialog: false,
      user: {
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        telephone: "",
        companyName: "",
        enabled: false,
        roles: [],
        language: "",
      },
      originalRoles: [],
      submitted: false,
      loadingButton: false,
      languages: [
        { name: this.$t("global.english"), code: "en" },
        { name: this.$t("global.greek"), code: "el" },
      ],
    };
  },
  computed: {
    ...mapGetters(["roles"]),
  },
  validations() {
    return {
      user: {
        username: {
          required,
        },
        firstName: {
          required,
        },
        lastName: {
          required,
        },
        email: {
          required,
          email,
        },
        // telephone: {
        //   ...(this.userId == null
        //     ? [
        //         {
        //           required,
        //           numeric,
        //           minLength: minLength(10),
        //           maxLength: maxLength(10),
        //         },
        //       ]
        //     : []),
        // },
        // companyName: {
        //   ...(this.userId == null ? [{ required }] : []),
        // },
        // language: {
        //   ...(this.userId == null ? [{ required }] : []),
        // },
        // roles: {
        //   required,
        // },
      },
    };
  },
  watch: {
    display(val) {
      this.viewDialog = val;
      if (this.userId != null) {
        this.user = { ...this.$store.getters.getUser(this.userId) };
        Object.assign(this.originalRoles, this.user.roles);
        console.log(this.user);
        this.user.roles.forEach((el) => {
          el.translatedName = this.$t(`userRoles.${el.name}`);
        });
      }
      console.log(this.userId);
      console.log(this.user);
    },
    viewDialog(val) {
      if (val == false) {
        this.close();
      } else {
        this.submitted = false;
      }
    },
  },

  methods: {
    close(val) {
      this.$emit("closeAddUserDialog", val);
      this.user = {};
    },

    async create() {
      this.submitted = true;
      const result = await this.v$.$validate();
      console.log(result);
      console.log(this.v$);
      if (result) {
        if (this.userId == null) {
          try {
            this.loadingButton = true;
            await this.$store.dispatch("createUser", this.user).then(() => {
              this.$action("user-create");
            });
            await this.$store
              .dispatch("userByEmail", this.user.email)
              .then(async (res) => {
                console.log(res);
                await this.$store.dispatch("addRoleToUser", {
                  userId: res[0].id,
                  roles: this.user.roles,
                });
              });
            this.$notification(
              this.$t("users.addNewUser"),
              this.$t("users.userCreated")
            );

            this.$notification(
              this.$t("users.addNewUser"),
              this.$t("users.reloadTableMessage"),
              "info"
            );

            this.close(true);
            this.loadingButton = false;
          } catch (err) {
            // this.$notification(
            //   this.$t("users.addNewUser"),
            //   this.$t("global.errorMessage"),
            //   "error"
            // );
            this.loadingButton = false;
          }
        } else {
          const deletedRoles = this.originalRoles.filter(
            (el) =>
              !this.user.roles.some((el1) => {
                return el1.id === el.id;
              })
          );

          const newRoles = this.user.roles.filter(
            (el) =>
              !this.originalRoles.some((el1) => {
                return el1.id === el.id;
              })
          );
          try {
            this.loadingButton = true;
            await this.$store.dispatch("updateUser", this.user).then(() => {
              this.$action("user-update", {
                targetUsername: this.user.username,
              });
            });

            if (newRoles.length > 0) {
              await this.$store.dispatch("addRoleToUser", {
                userId: this.userId,
                roles: newRoles,
              });
            }

            if (deletedRoles.length > 0) {
              await this.$store.dispatch("deleteRoleFromUser", {
                userId: this.userId,
                roles: deletedRoles,
              });
            }
            this.$notification(
              this.$t("users.updateUser"),
              this.$t("users.userUpdated")
            );

            this.$notification(
              this.$t("users.updateUser"),
              this.$t("users.reloadTableMessage"),
              "info"
            );

            this.close(true);
            this.loadingButton = false;
          } catch (err) {
            // this.$notification(
            //   this.$t("users.updateUser"),
            //   this.$t("global.errorMessage"),
            //   "error"
            // );
            this.loadingButton = false;
          }
        }
      }
    },
  },
};
</script>

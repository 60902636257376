<template>
  <AddUser
    :display="addEditUserVisible"
    @closeAddUserDialog="closeAddUser"
    :userId="userId"
  ></AddUser>
  <div>
    <UsersTable
      @editUser="viewEditUser"
      @addUser="viewAddUser"
      :showAddUser="true"
      :callUsers="callUsers"
    ></UsersTable>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AddUser from "./CreateEditUser.vue";
import UsersTable from "./UsersTable.vue";

export default {
  components: {
    AddUser,
    UsersTable,
  },
  name: "Users",
  data() {
    return {
      addEditUserVisible: false,
      userId: null,
      callUsers: false
    };
  },
  computed: {
    ...mapGetters(['users'])
  },
  mounted() {
    this.$action("users-page-view");
  },
  methods: {
    viewAddUser() {
      this.addEditUserVisible = true;
    },
    viewEditUser(id) {
      this.userId = id;
      this.addEditUserVisible = true;
    },
    closeAddUser(value) {
      if (value == true) {
        // this.loading = true;
        // this.getUsers();
        this.callUsers = true;
      }else {
        this.callUsers = false;
      }
      this.userId = null;
      this.addEditUserVisible = false;
    },
    async getUsers() {
      await this.$store.dispatch("users");
      await Promise.all(
        this.users.map(async (el) => {
          this.$store.dispatch("userRoles", el.id);
        })
      );
      this.loading = false;
    },
  },
};
</script>
